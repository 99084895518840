<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody>
            <tr>
              <td>
                <v-chip
                  small
                  label
                  color="info"
                  dark
                >
                  N
                </v-chip>
              </td>
              <td>
                Unidades a instalar/facturar
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin]
}
</script>
